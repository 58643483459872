import React from "react"
import { Helmet } from "react-helmet"
import { FormattedMessage } from "gatsby-plugin-intl"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import CallToAction from "../components/CallToAction"
import Head from "../components/Head"

const Contact = () => {
    return (
        <Layout>
            <Head title="Contact us - Muun" description="" />
            <div className="page">
                <Header className="light" />
                <div className="content container">
                    <h1><FormattedMessage id="contact_us" defaultMessage="Contact us" /></h1>
                    <p className="mb-3">
                        <FormattedMessage
                            id="contact_us_text"
                            defaultMessage="Send us an email and get in touch with our team. We usually respond within a few hours."
                        />
                    </p>
                    <div className="row">
                        <div className="col-lg-6">
                            <h3><FormattedMessage id="support" defaultMessage="Support" /></h3>
                            <p><FormattedMessage id="support_text" defaultMessage="Send us an email to get help or report bugs." /></p>
                            <p><a href="mailto:support@muun.com">support@muun.com</a></p>

                            <h3><FormattedMessage id="contact" defaultMessage="Contact" /></h3>
                            <p><FormattedMessage id="contact_text" defaultMessage="Send us an email if you have a question or want to chat." /></p>
                            <p><a href="mailto:contact@muun.com">contact@muun.com</a></p>

                            <h3><FormattedMessage id="responsible_disclosure" defaultMessage="Responsible Disclosure" /></h3>
                            <p><FormattedMessage id="responsible_disclosure_text" defaultMessage="Send us an email to report any security related bugs or vulnerabilities." /></p>
                            <p><a href="mailto:security@muun.com">security@muun.com</a></p>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="well mt-5 mx-lg-3">
                                <p><FormattedMessage id="encrypt_your_message" defaultMessage="You can encrypt your email message using our public PGP key." /></p>

                                <h5><FormattedMessage id="fingerprint" defaultMessage="Fingerprint" /></h5>
                                <pre>{"1299 28C1 E79F E011 6DA4\nC80F 8DB7 FD0F 61E6 ED76"}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CallToAction className="d-none d-md-block" />
            <Footer />
        </Layout>
    )
}

export default Contact
